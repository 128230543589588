<template>
  <div class="">
    <div class="">
      <h5 class="mb-3">ยังไม่ได้วางแผน</h5>
      <ul>
        <li v-for="doc in unplannings" :key="doc.id" class="mb-3">
          <router-link
            class="text-decoration-none"
            :to="toDetail(doc.id, doc.type)">
            {{doc.code}}
          </router-link>
          | {{doc.name}}
          <div class="">
            <small class="text-primary">{{doc.remark}}</small>
          </div>
          <div class="">
            <small class="text-secondary">
              กำหนดการ:
              <span v-if="doc.producedAt" class="text-light">
                {{doc.producedAt | datetime}}
              </span>
              <span v-else class="text-light">-</span> |
              เริ่มการผลิต:
              <span class="text-warning" v-if="doc.approvedAt">
                {{doc.approvedAt | datetime}}
              </span>
              <span v-else class="text-light">-</span> |
              จัดส่งก่อนวันที่:
              <span class="text-danger" v-if="doc.deliveredAt">
                {{doc.deliveredAt | datetime}}
              </span>
              <span v-else class="text-light">-</span>
              สิ้นสุดใน:
              <span
                v-if="doc.overallTime"
                :class="{'text-success': doc.overallTime >= 0, 'text-danger': doc.overallTime < 0}">
                {{getTime(doc.overallTime)}}
              </span>
              <span v-else class="text-light">-</span>
            </small>
          </div>
        </li>
      </ul>
    </div>

    <h5 class="mb-3 mt-5">แผนการผลิต</h5>

    <div class="row">
      <div
        class="col-12 mb-4"
        v-for="machine in machines"
        :key="machine.id">

        <div class="card">
          <div class="card-body pb-1">
            <h5 class="card-title">{{machine.code}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{machine.name}}</h6>
            <div class="card-text">
              <ul class="list-unstyled">
                <li v-for="(doc, i) in machine.docs" :key="doc.id" class="mb-3">
                  {{i+1}}.
                  <router-link
                    class="text-decoration-none"
                    :to="toDetail(doc.id, doc.type)">
                    <span :class="{'text-warning': doc.approvedAt}">
                      {{doc.code}}
                    </span>
                  </router-link>
                  | {{doc.name}}

                  <ListPlanningForm
                    class="ml-2"
                    :docId="doc.id"
                    :docCode="doc.code"
                    :templateType="templateType"
                    :procedureType="procedureType"
                    @updated="fetchData">
                  </ListPlanningForm>

                  <span
                    v-if="!doc.approvedAt && $auth.hasRole(`procedure:${procedureType}:planning:edit`)"
                    class="float-right">
                    <fa
                      icon="arrow-down"
                      class="text-primary pointer ml-2"
                      @click="moveDoc(doc.id, -1)">
                    </fa>
                    <fa
                      icon="arrow-up"
                      class="text-primary pointer ml-2"
                      @click="moveDoc(doc.id, 1)">
                    </fa>
                  </span>

                  <div class="">
                    <small class="text-primary">{{doc.remark}}</small>
                  </div>

                  <div class="">
                    <small class="text-secondary">
                      กำหนดการ:
                      <span v-if="doc.producedAt" class="text-light">
                        {{doc.producedAt | datetime}}
                      </span>
                      <span v-else class="text-light">-</span> |
                      เริ่มการผลิต:
                      <span class="text-warning" v-if="doc.approvedAt">
                        {{doc.approvedAt | datetime}}
                      </span>
                      <span v-else class="text-light">-</span> |
                      จัดส่งก่อนวันที่:
                      <span class="text-danger" v-if="doc.deliveredAt">
                        {{doc.deliveredAt | datetime}}
                      </span>
                      <span v-else class="text-light">-</span> |
                      สิ้นสุดใน:
                      <span
                        v-if="doc.overallTime"
                        :class="{'text-success': doc.overallTime >= 0, 'text-danger': doc.overallTime < 0}">
                        {{getTime(doc.overallTime)}}
                      </span>
                      <span v-else class="text-light">-</span>
                    </small>
                  </div>

                  <div class="mb-1" v-if="doc.procedureId">
                    <small>
                      <span class="text-secondary">การผลิต:</span>
                      {{doc.procedure.name}}
                    </small>
                  </div>

                  <div v-if="doc.approvedAt" class="row">
                    <div class="col-12">
                      <ListPlanningProgress
                        class="mb-2"
                        v-for="order in doc.orders"
                        :key="order.id"
                        :totalQty="totalQty(order.children)"
                        :minQty="order.minQty"
                        :maxQty="order.maxQty">
                        <span class="pr-1">{{order.inventory.code}} ({{order.inventory.name}})</span>
                        <span>{{totalQty(order.children)}} / {{order.maxQty}}</span>
                        <span class="pl-1">{{order.inventory.unit.name}}</span>
                      </ListPlanningProgress>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { LIST_MACHINE, MOVE_DOC } from './graph'
import ListPlanningProgress from './ListPlanningProgress'
import ListPlanningForm from './ListPlanningForm'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      unplannings: [],
      machines: [],
      timer: null
    }
  },
  methods: {
    getTime (seconds) {
      let hr = Math.floor(seconds / 3600)
      let mi = Math.floor((seconds % 3600) / 60)
      return `${Math.abs(hr)} ชั่วโมง ${Math.abs(mi)} นาที`
    },
    toDetail (id, type) {
      return {
        name: `Doc${this.$form.capitalize(type)}Detail`,
        params: {docId: id}
      }
    },
    totalQty (orders) {
      return Math.abs(orders.reduce((t,v) => t += v.qty, 0))
    },
    fetchData () {
      this.$apollo.query({
        query: LIST_MACHINE(this.templateType),
        variables: {
          procedureType: this.procedureType
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.unplannings = res.data.unplannings
        this.machines = res.data.machines.map(m => {
          return {
            ...m,
            docs: this.sortDocByOrder(m.docs)
          }
        })
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    sortDocByOrder (docs) {
      return [
        ...docs.filter(d => d.approvedAt),
        ...docs
        .filter(d => !d.approvedAt)
        .sort((a,b) => {
          const pa = a.plannings.find(v => v.tag === 'producedAt')
          const pb = b.plannings.find(v => v.tag === 'producedAt')

          return pb.order - pa.order
          || a.producedAt - b.producedAt
          || a.deliveredAt - b.deliveredAt
          || a.id - b.id
        })
      ]
    },
    moveDoc (docId, step) {
      this.$apollo.mutate({
        mutation: MOVE_DOC(this.templateType),
        variables: {
          procedureType: this.procedureType,
          docId,
          step
        }
      })
      .then(() => {
        this.fetchData()
      })
      .catch(this.$toasted.global.error)
    }
  },
  created () {
    this.fetchData ()
    this.timer = setInterval(() => {
      this.fetchData()
    }, 60000)
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  components: {
    ListPlanningProgress,
    ListPlanningForm
  }
}
</script>

<style lang="css" scoped>
</style>
