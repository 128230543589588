import gql from 'graphql-tag'


export const LIST_MACHINE = (templateType) => gql`query LIST_MACHINE ($procedureType: String!) {
  unplannings: listProcedure${templateType}Unplanning (procedureType: $procedureType) {
    id type code name remark
    producedAt deliveredAt
    createdAt approvedAt closedAt
  }

  machines: listProcedure${templateType}Machine (procedureType: $procedureType) {
    id type code name
    docs {
      id type code name remark qty
      producedAt deliveredAt
      createdAt approvedAt closedAt
      orders {
        id type name minQty maxQty
        inventoryId inventory {
          id type code name
          unitId unit {id name}
        }
        children {
          id type qty
        }
      }
      plannings {id type tag plannedAt order}
      overallTime
      procedureId procedure {id name}
    }
  }
}`

export const DETAIL_DOC = (templateType) => gql`query DETAIL_DOC ($procedureType: String!, $docId: Int!) {
  doc: detailProcedure${templateType}Doc (procedureType: $procedureType, docId: $docId) {
    id type code name remark
    deliveredAt producedAt procedureId
  }
}`

export const UPDATE_DOC = (templateType) => gql`mutation UPDATE_DOC ($procedureType: String!, $docId: Int!, $input: Procedure${templateType}DocInput!) {
  updateDoc: updateProcedure${templateType}Doc (procedureType: $procedureType, docId: $docId, input: $input) {id}
}`

export const MOVE_DOC = (templateType) => gql`mutation MOVE_DOC ($procedureType: String!, $docId: Int!, $step: Int!) {
  moveDoc: moveProcedure${templateType}Doc (procedureType: $procedureType, docId: $docId, step: $step) {id}
}`
