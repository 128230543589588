<template>
  <span class="">
    <span
      class="text-warning pointer"
      @click="toggle = !toggle">
      <fa icon="pencil-alt"></fa>
    </span>

    <b-modal v-model="toggle" :title="docCode">
      <div class="form-row">
        <sgv-input-text
          class="col-12"
          label="หมายเหตุ"
          v-model="formData.remark">
        </sgv-input-text>

        <sgv-input-datetime
          isNull
          :disabled="!$auth.hasRole(`procedure:${procedureType}:planning:edit`)"
          class="col-6"
          v-model="formData.producedAt"
          label="เวลาสั่งผลิต">
        </sgv-input-datetime>

        <sgv-input-datetime
          isNull
          :disabled="!$auth.hasRole(`procedure:${procedureType}:planning:edit:delivery`)"
          class="col-6"
          v-model="formData.deliveredAt"
          label="เวลาจัดส่ง">
        </sgv-input-datetime>
      </div>

      <button
        slot="modal-footer"
        type="button"
        class="btn btn-warning btn-block"
        @click="updateData">
        แก้ไข
      </button>
    </b-modal>
  </span>
</template>

<script>
import {
  DETAIL_DOC, UPDATE_DOC
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {
        remark: '',
        deliveredAt: null,
        producedAt: null,
      },
      toggle: false
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC(this.templateType),
        variables: {
          procedureType: this.procedureType,
          docId: this.docId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setFormData(res.data.doc)
      })
      .catch(this.$toasted.global.error)
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (['deliveredAt', 'producedAt'].includes(key)) {
          this.formData[key] = this.$date.format(v[key]).datetime
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    updateData () {
      const input = {
        remark: this.formData.remark,
        deliveredAt: this.formData.deliveredAt ? this.$date.format(this.formData.deliveredAt).raw : null,
        producedAt: this.formData.producedAt ? this.$date.format(this.formData.producedAt).raw : null
      }

      this.$apollo.mutate({
        mutation: UPDATE_DOC(this.templateType),
        variables: {
          procedureType: this.procedureType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.toggle = false
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  },
  watch: {
    toggle (value) {
      if (value) this.dataDetail()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
