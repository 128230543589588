<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <sgv-input-select
            :options="options"
            v-model="docStatus"
            select="value">
            <template slot-scope="item">{{item.text}}</template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListPlanning
      v-if="docStatus === 'planning'"
      :procedureType="procedureType"
      :templateType="templateType">
    </ListPlanning>
  </div>
</template>

<script>
import ListPlanning from './ListPlanning.vue'
import retainMixin from '@/mixins/retain-mixin'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Procedure$${this.$form.capitalize(this.procedureType)}Planning`,
      docStatus: 'planning',
      rFields: ['planning'],
      options: [
        {text: 'แผนการผลิต', value: 'planning'},
      ]
    }
  },
  watch: {
    docStatus: 'setRetaining'
  },
  components: {
    ListPlanning,
  }
}
</script>

<style lang="css" scoped>
</style>
