<template>
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped bg-primary"
      role="progressbar"
      :style="total.style"
      :aria-valuenow="total.qty"
      :aria-valuemin="total.minQty"
      :aria-valuemax="total.maxQty">
      <small class="label-center">
        <slot></slot>
      </small>
    </div>
  </div>
</template>

<script>
import round from 'lodash/round'

export default {
  props: {
    totalQty: {
      type: Number,
      required: true
    },
    minQty: {
      type: Number,
      required: true
    },
    maxQty: {
      type: Number,
      required: true
    }
  },
  computed: {
    total () {
      const x = (this.maxQty - 0) / 100
      return {
        style: {'width': round(this.totalQty / x) + '%'},
        qty: round(this.totalQty / x),
        minQty: round(this.minQty / x),
        maxQty: round(this.maxQty / x)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.label-center {
  position: absolute;
  text-align: center;
  overflow: hidden;
  width: 100%;
}
</style>
